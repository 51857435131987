<template>
  <div class="unit-interpretation">
    <ul class="top-menus">
      <li class="top-menu-item" @click="editTarget('mindMap')">
        修改单元设计框架
      </li>
      <li class="top-menu-item" @click="showMindMapAction">单元设计框架</li>
      <unit-interpretion-menu-item
        text="单元导学"
        :apxs="dxApxs"
        :missApxs="dxMissApxs"
        @uploadFile="uploadDxFile"
        @preview="previewDxFile"
        @previewmiss="previewDxMissFile"
        @toolDelete="deleteDxApxFile"
        @recoveryTool="recoveryDxMissApxFile"
        @permanently="permanentlyDxMissApxFile"
      ></unit-interpretion-menu-item>
      <unit-interpretion-menu-item
        text="单元评价"
        :apxs="evalApxs"
        :missApxs="evalMissApxs"
        @uploadFile="uploadEvalFile"
        @preview="previewEvalFile"
        @previewmiss="previewEvalMissFile"
        @toolDelete="deleteEvalApxFile"
        @recoveryTool="recoveryEvalMissApxFile"
        @permanently="permanentlyEvalMissApxFile"
      ></unit-interpretion-menu-item>
    </ul>

    <div class="unit-target-warp" v-if="current == 0">
      <!-- 单元背景 -->
      <lesson-design-header
        :canEdit="true"
        :editIcon="editIcon"
        @edit="editTarget('background')"
        bgColor="#EDF0F5"
      >
        <img src="@/assets/dybj.png" slot="icon" alt="" srcset="" />
        <!-- <div slot="content" class="background-content">
          <p v-html="background"></p>
        </div> -->
        <div slot="content">
          <span style="white-space: pre-wrap" v-html="background"></span>
        </div>
      </lesson-design-header>

      <!-- 单元目标 -->
      <lesson-design-header
        :canEdit="true"
        :editIcon="editIcon"
        @edit="editTarget('target')"
        bgColor="#EDF0F5"
      >
        <img src="@/assets/dymb.png" slot="icon" alt="" srcset="" />
        <!-- <div slot="content" class="background-content">
          <p v-html="target"></p>
        </div> -->
        <div class="background-content" slot="content">
          <p v-html="target"></p>
        </div>
      </lesson-design-header>

      <!-- 单元结构 -->
      <lesson-design-header
        :canEdit="true"
        :editIcon="editIcon"
        @edit="editTarget('evaluation')"
        bgColor="#EDF0F5"
      >
        <img src="@/assets/dyjg.png" slot="icon" alt="" srcset="" />
        <!-- text-center -->
        <!-- <div slot="content" class="background-content">
          <p v-html="evaluation"></p>
        </div> -->
        <div slot="content">
          <p v-html="evaluation"></p>
        </div>
      </lesson-design-header>
      <!-- 课时规划 -->
      <lesson-design-header
        :canEdit="true"
        :editIcon="editIcon"
        @edit="editTarget('planning')"
        bgColor="#EDF0F5"
      >
        <img src="@/assets/ksgh.png" slot="icon" alt="" srcset="" />
        <!-- <div class="table background-content" slot="content">
          <p v-html="planning"></p>
        </div> -->
        <div class="table background-content" slot="content">
          <p v-html="planning"></p>
        </div>
      </lesson-design-header>
      <!-- 课时建议 -->
      <lesson-design-header
        :canEdit="true"
        :editIcon="editIcon"
        @edit="editTarget('suggestion')"
        bgColor="#EDF0F5"
      >
        <img src="@/assets/ksjy.png" slot="icon" alt="" srcset="" />
        <!-- <div slot="content" class="background-content">
          <p v-html="suggestion"></p>
        </div> -->
        <div class="background-content" slot="content">
          <p v-html="suggestion"></p>
        </div>
      </lesson-design-header>
    </div>
    <img src="@/assets/gy.png" class="footer-gy" alt="" srcset="" />

    <a-modal
      width="100%"
      centered
      :zIndex="2000"
      :visible="showPlayer"
      :footer="null"
      title=""
      @cancel="hidePlayer"
    >
      <source-player
        :previewIndex="previewIndex"
        :sources="previewSource"
        :ispreview="ispreview"
      ></source-player>
    </a-modal>
    <a-modal
      centered
      :visible="showMindMap"
      :footer="null"
      width="100%"
      @cancel="hideMindMap"
    >
      <p v-html="mindMap"></p>
      <a-empty v-if="mindMap.length == 0" description="暂无单元框架设计" />
    </a-modal>
    <a-modal
      v-model="editorVisible"
      :title="editorTitle"
      :confirmLoading="editorUpdateLoading"
      okText="确定"
      cancelText="取消"
      :destroyOnClose="true"
      @ok="modifyEditorAction"
      :width="1000"
    >
      <myEditor id="tinymce" v-model="editorContent" :init="init"></myEditor>
    </a-modal>
  </div>
</template>

<script>
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
// 这里引入报错可以替换成 import 'tinymce/themes/modern/theme'import "tinymce/icons/default";
//显示富文本图标，如果不加富文本图标显示会变成 !not found
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/paste";

import editIcon from "@/assets/edit.png";
import gjkb from "@/assets/gjkb.png";
import bsjd from "@/assets/bsjd.png";
import wdjd from "@/assets/wdjd.png";
import linkicon from "@/assets/link.png";
import LessonDesignHeader from "../../../../components/LessonDesignHeader.vue";
import {
  getUnitExplainById,
  getUnitApxList,
  delReUnitApx,
  addUnitApx,
  updateUnit,
  removeApx,
} from "@/api/newLesston";
// import SeeksRelationGraph from "relation-graph";
import UnitInterpretionMenuItem from "../../../../components/UnitInterpretionMenuItem.vue";
import SourcePlayer from "../../../../components/SourcePlayer.vue";
const columns = [
  {
    title: "课时划分",
    dataIndex: "name",
    key: "name",
    width: 122,
  },
  {
    title: "课时内容",
    dataIndex: "content",
    key: "content",
    width: 122,
  },
  {
    title: "课时目标",
    dataIndex: "target",
    key: "target",
    ellipsis: false,
  },
];

const options = {
  defaultFocusRootNode: false,
  layouts: [
    {
      label: "树",
      layoutName: "tree",
      layoutClassName: "seeks-layout-center",
      defaultNodeShape: 1,
      defaultLineShape: 1,
      defaultJunctionPoint: "tb",
      from: "top",
      // 通过这4个属性来调整 tree-层级距离&节点距离
      min_per_width: "200",
      max_per_width: "400",
      min_per_height: "80",
      max_per_height: undefined,
      levelDistance: "", // 如果此选项有值，则优先级高于上面那4个选项
    },
  ],
  defaultLineMarker: {
    markerWidth: 12,
    markerHeight: 12,
    refX: 6,
    refY: 6,
    data: "M2,2 L10,6 L2,10 L6,6 L2,2",
  },
  defaultNodeShape: 1,
  defaultNodeWidth: "122",
  defaultLineShape: 6,
  defaultJunctionPoint: "tb",
  defaultNodeBorderWidth: 1,
  defaultLineColor: "#8E8E93",
  defaultNodeColor: "#ffffff",
  defaultNodeFontColor: "#2A2A31",
  defaultNodeBorderColor: "#8E8E93",
};

export default {
  components: {
    LessonDesignHeader,
    // SeeksRelationGraph,
    UnitInterpretionMenuItem,
    SourcePlayer,
    myEditor: Editor,
  },
  data() {
    return {
      gjkb: gjkb,
      bsjd: bsjd,
      wdjd: wdjd,
      current: 0,
      datas: [],
      columns,
      background: "",
      mindMap: "",
      evaluation: "",
      planning: "",
      target: "",
      suggestion: "",
      graphOptions: options,
      dxApxs: [],
      dxMissApxs: [],
      evalApxs: [],
      evalMissApxs: [],
      showPlayer: false,
      previewSource: [],
      previewIndex: 0,
      ispreview: false,
      showMindMap: false,
      editIcon: editIcon,
      //富文本内容
      editorUpdateLoading: false,
      editorVisible: false,
      editorTitle: "",
      editorType: "",
      editorContent: "",
      init: {
        language_url: "/zh_CN.js", // 语言包位置，因为放在public下所以可以省略public
        selector: "#tinymce", //tinymce的id
        language: "zh_CN", //语言类型
        skin_url: "/skins/ui/oxide",
        height: 500, //编辑器高度
        branding: false, //是否禁用“Powered by TinyMCE”
        paste_data_images: true,
        plugins: "image lists table paste",
        toolbar:
          "fontselect fontsizeselect lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen",
        images_upload_url: "/api/mylesson/UploadFilesForTinyMCE",
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var xhr, formData;
          var file = blobInfo.blob(); //转化为易于理解的file对象
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", "/api/mylesson/UploadFilesForTinyMCE");
          xhr.onload = function () {
            var json;
            if (xhr.status != 200) {
              failFun("HTTP Error: " + xhr.status);
              return;
            }
            // json = JSON.parse(xhr.responseText);
            // console.log('json:'+json);
            // if (!json || typeof json != "string") {
            //   failFun("Invalid JSON: " + xhr.responseText);
            //   return;
            // }
            succFun(xhr.responseText);
          };
          formData = new FormData();
          formData.append("file", file, file.name); //此处与源文档不一样
          xhr.send(formData);
        },
      },
    };
  },
  mounted() {
    tinymce.init({});
    let courseRoute = {
      path: "/newLession/course/detail?course=" + this.$route.query.course,
      title: this.$route.query.course,
    };
    let route = {
      path: this.$route.fullPath,
      title: this.$route.query.title,
    };
    this.$store.commit("M_ADD_BOOK_ROUTER", [courseRoute, route]);
    this.requestUnitInfo();
    this.requestDxApxs();
    this.requestDxMissApxs();
    this.requestEvalApxs();
    this.requestEvalMissApxs();
  },
  methods: {
    requestUnitInfo() {
      var _this = this;
      getUnitExplainById({ unitId: this.$route.query.unitId }).then((res) => {
        console.log("11111111res", res);
        this.background = res.data.unitExplain.background || "";
        this.target = res.data.unitExplain.target || "";
        this.mindMap = res.data.unitExplain.mindMap || "";
        this.evaluation = res.data.unitExplain.evaluation || "";
        this.planning = res.data.unitExplain.planning || "";
        this.suggestion = res.data.unitExplain.suggestion || "";
        this.mindMap = res.data.unitExplain.mindMap || "";
        this.datas = res.data.unitExplain.plan || "";
        // this.createGrapData(res.data.unitExplain.structure);
      });
    },
    /**
     * 请求单元导学文件
     */
    requestDxApxs() {
      let params = { unitId: this.$route.query.unitId, type: 1, del: 1 };
      getUnitApxList({ model: JSON.stringify(params) }).then((res) => {
        console.log(res);
        this.dxApxs = res.data.apxList;
      });
    },
    /**
     * 请求单元导学删除的文件
     */
    requestDxMissApxs() {
      let params = { unitId: this.$route.query.unitId, type: 1, del: 0 };
      getUnitApxList({ model: JSON.stringify(params) }).then((res) => {
        console.log(res);
        this.dxMissApxs = res.data.apxList;
      });
    },
    /**
     * 请求单元评价文件
     */
    requestEvalApxs() {
      let params = { unitId: this.$route.query.unitId, type: 2, del: 1 };
      getUnitApxList({ model: JSON.stringify(params) }).then((res) => {
        console.log(res);
        this.evalApxs = res.data.apxList;
      });
    },
    /**
     * 请求单元评价删除文件
     */
    requestEvalMissApxs() {
      let params = { unitId: this.$route.query.unitId, type: 2, del: 0 };
      getUnitApxList({ model: JSON.stringify(params) }).then((res) => {
        console.log(res);
        this.evalApxs = res.data.apxList;
      });
    },
    /**
     * 上传单元导学的文件
     */
    uploadDxFile(params) {
      params.unitId = this.$route.query.unitId;
      params.appendixType = 1;
      addUnitApx({ model: JSON.stringify(params) }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dxApxs.push(res.data.newAppendix);
        }
      });
    },
    /**
     * 上传单元评价的文件
     */
    uploadEvalFile(params) {
      params.unitId = this.$route.query.unitId;
      params.appendixType = 2;
      addUnitApx({ model: JSON.stringify(params) }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.evalApxs.push(res.data.newAppendix);
        }
      });
    },
    /**
     * 预览单元导学文件
     */
    previewDxFile(params) {
      this.showPlayerWidthSource(params.index, this.dxApxs);
    },
    /**
     * 预览单元导学已删除的文件
     */
    previewDxMissFile(params) {
      this.showPlayerWidthSource(params.index, this.dxMissApxs);
    },
    /**
     * 预览单元评价的文件
     */
    previewEvalFile(params) {
      this.showPlayerWidthSource(params.index, this.evalApxs);
    },
    /**
     * 预览单元评价已删除的文件
     */
    previewEvalMissFile(params) {
      this.showPlayerWidthSource(params.index, this.evalMissApxs);
    },
    showPlayerWidthSource(index, sources) {
      this.ispreview = true;
      this.previewSource = sources;
      this.previewIndex = index;
      this.showPlayer = true;
    },
    hidePlayer() {
      this.showPlayer = false;
    },
    /**
     * 删除单元导学的文件
     */
    deleteDxApxFile(params) {
      delReUnitApx({ apxId: params.AppendixId, type: 0 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dxApxs.splice(params.index, 1);
          this.dxMissApxs.push(params);
        }
      });
    },
    /**
     * 恢复单元导学的文件
     */
    recoveryDxMissApxFile(params) {
      delReUnitApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dxMissApxs.splice(params.index, 1);
          this.dxApxs.push(params);
        }
      });
    },
    /**
     * 永久删除单元导学文件
     */
    permanentlyDxMissApxFile(params) {
      removeApx({ apxId: params.AppendixId, type: 3 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dxMissApxs.splice(params.index, 1);
        }
      });
    },
    /**
     * 删除单元陪评价文件
     */
    deleteEvalApxFile(params) {
      delReUnitApx({ apxId: params.AppendixId, type: 0 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.evalApxs.splice(params.index, 1);
          this.evalMissApxs.push(params);
        }
      });
    },
    /**
     * 恢复单元评价文件
     */
    recoveryEvalMissApxFile(params) {
      delReUnitApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.evalMissApxs.splice(params.index, 1);
          this.evalApxs.push(params);
        }
      });
    },
    /**
     * 永久删除单元评价文件
     */
    permanentlyEvalMissApxFile(params) {
      removeApx({ apxId: params.AppendixId, type: 3 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.evalMissApxs.splice(params.index, 1);
        }
      });
    },
    createGrapData(structure) {
      let json = {
        rootId: "0",
        nodes: [],
        links: [],
      };
      json.nodes.push({
        id: "0",
        text: structure.name,
        styleClass: "structure-node",
        disableDefaultClickEffect: true,
      });
      let lessons = structure.lessonList;
      for (let i = 0; i < lessons.length; i++) {
        let item = lessons[i];
        let pnode = {
          id: item.lessonId,
          text: item.lessonName,
          styleClass: "structure-node",
          disableDefaultClickEffect: true,
        };
        json.nodes.push(pnode);
        let link = { from: "0", to: String(item.lessonId) };
        json.links.push(link);
        let sectionList = item.sectionList;
        for (let j = 0; j < sectionList.length; j++) {
          let sectionNode = sectionList[j];
          let html = `<div class="structure-node-link">
                        <img src=${linkicon} />
                        <span class="section-name">${sectionNode.sectionName}</span>
                      </div>`;
          let node = {
            id: String("s_" + sectionNode.sectionId),
            borderColor: "#6E76FF",
            html: html,
            fontColor: "#6E76FF",
          };
          json.nodes.push(node);
          let link = {
            from: String(item.lessonId),
            to: String("s_" + sectionNode.sectionId),
          };
          json.links.push(link);
        }
      }
      this.$refs.seeksRelationGraph.setJsonData(json, (seeksRGGraph) => {
        // 这些写上当图谱初始化完成后需要执行的代码
        console.log(seeksRGGraph);
      });
    },
    onNodeClick(node) {
      console.log(node);
      if (node.id.indexOf("s_") != -1) {
        let query = this.$route.query;
        query.sectionId = node.id.replace("s_", "");
        this.$router.push({ name: "lessonDesign", query });
      }
    },
    onNodeExpand(node, e) {
      console.log(e);
      // 当有一些节点被显示或隐藏起来时，会让图谱看着很难看，需要布局器重新为节点分配位置，所以这里需要调用refresh方法来重新布局
      console.log("onNodeExpand:", node);
      this.$refs.seeksRelationGraph.refresh();
    },
    showMindMapAction() {
      this.showMindMap = true;
    },
    hideMindMap() {
      this.showMindMap = false;
    },
    /**
     * 修改课时目标
     */
    editTarget(type) {
      this.editorType = type;
      switch (type) {
        case "background":
          this.editorTitle = "编辑单元背景";
          this.editorContent = this.background;
          break;
        case "evaluation":
          this.editorTitle = "编辑单元结构";
          this.editorContent = this.evaluation;
          break;
        case "planning":
          this.editorTitle = "编辑课时规划";
          this.editorContent = this.planning;
          break;
        case "target":
          this.editorTitle = "编辑单元目标";
          this.editorContent = this.target;
          break;
        case "suggestion":
          this.editorTitle = "编辑教学建议";
          this.editorContent = this.suggestion;
          break;
        default:
          this.editorTitle = "修改单元设计框架";
          this.editorContent = this.mindMap;
          break;
      }
      this.editorVisible = true;
    },
    /**
     * 修改单元背景
     */
    saveSectionBackground() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "unitbackground",
        newValue: this.background,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 修改单元目标
     */
    saveSectionTarget() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "unittarget",
        newValue: this.target,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 修改单元结构
     */
    saveSectionEvaluation() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "unitevaluation",
        newValue: this.evaluation,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 修改课时规划
     */
    saveSectionPlanning() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "lessonplanning",
        newValue: this.planning,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 修改教学建议
     */
    saveSectionSuggestion() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "teachingsuggestion",
        newValue: this.suggestion,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 修改单元设计框架
     */
    modifyMindMapAction() {
      updateUnit({
        unitid: this.$route.query.unitId,
        filed: "unitmindmap",
        newValue: this.mindMap,
      }).then((res) => {
        this.editorUpdateLoading = false;
        this.editorVisible = false;
        this.$message.success(res.msg);
      });
    },
    /**
     * 富文本编辑完毕
     */
    modifyEditorAction() {
      switch (this.editorType) {
        case "background":
          this.background = this.editorContent;
          this.saveSectionBackground();
          break;
        case "evaluation":
          this.evaluation = this.editorContent;
          this.saveSectionEvaluation();
          break;
        case "planning":
          this.planning = this.editorContent;
          this.saveSectionPlanning();
          break;
        case "target":
          this.target = this.editorContent;
          this.saveSectionTarget();
          break;
        case "suggestion":
          this.suggestion = this.editorContent;
          this.saveSectionSuggestion();
          break;
        default:
          this.mindMap = this.editorContent;
          this.modifyMindMapAction();
          break;
      }
      this.editorUpdateLoading = true;
    },
  },
};
</script>

<style lang="less">
.unit-interpretation {
  padding: 24px 20px 24px 118px;
  .background-content {
    img {
      width: 100%;
    }
    p {
      margin: 0;
    }
  }
  .text-center {
    height: 600px;
    width: 100%;
    .structure-node {
      border-radius: 2px;
    }
    .structure-node-link {
      border-radius: 2px;
      border: 1px solid #6e76ff;
      color: #6e76ff;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      background: white;
      .section-name {
        padding-left: 10px;
        font-size: 12px;
      }
    }
  }
  .top-menus {
    display: flex;
    justify-content: flex-end;
    .top-menu-item {
      padding: 7px 12px;
      border-radius: 24px;
      font-size: 12px;
      background: #e8e9ff;
      border: 1px solid #bec1ff;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .unit-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    .unit-tab-item {
      border: 1px solid #8e8e93;
      opacity: 1;
      border-radius: 2px;
      font-size: 14px;
      color: #2a2a31;
      padding: 4px 16px;
      background: white;
      margin: 0 10px;
      cursor: pointer;
    }
    .unit-tab-item-select {
      border: 1px solid @primary-color;
      color: white;
      background: @primary-color;
    }
  }
  .table {
    background: white;
    .ant-table-content {
      border-left-color: #8e8e93;
    }
    .ant-table-body {
      border-color: #8e8e93;
      .ant-table-thead,
      th {
        background: #002a68;
        color: white;
        border-color: #8e8e93;
      }
      td,
      tr,
      table {
        border-color: #8e8e93;
        border-left-color: #8e8e93;
        white-space: pre-wrap;
      }
    }
  }
  .copy {
    text-align: right;
    color: @primary-color;
  }
  .footer-gy {
    width: 1140px;
    height: 48px;
    display: block;
    margin: 0 auto;
  }
}
</style>